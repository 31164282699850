import { telegram } from "./telegram"

export async function sendSupportEmail(email, message) {
  if (!email || !message) {
    const error = "Email or message is undefined or null"
    throw new Error(error)
  }

  const data = {
    email: email,
    message: message,
  }

  try {
    const response = await fetch(
      "https://send-support-email-oak26mtdga-ey.a.run.app/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
    if (!response.ok) {
      const error = `Email sending failed: ${response.statusText}`
      await telegram(error)
      throw new Error(error)
    }

    const result = await response.text()
    return result
  } catch (error) {
    await telegram(`Email sending error: ${error.message}`)
    throw error
  }
}
