import { telegram } from "./telegram"

async function convertAvifToJpeg(file) {
  const img = new Image()
  img.src = URL.createObjectURL(file)

  await new Promise((resolve) => (img.onload = resolve))

  const canvas = document.createElement("canvas")
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext("2d")
  ctx.drawImage(img, 0, 0)

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(
          new File([blob], file.name.replace(".avif", ".jpg"), {
            type: "image/jpeg",
          })
        )
      },
      "image/jpeg",
      0.95
    )
  })
}

export async function uploadToR2(file) {
  if (!file) {
    const error = "File is undefined or null"
    await telegram(error)
    throw new Error(error)
  }

  // Konvertiere AVIF zu JPEG wenn nötig
  let uploadFile = file
  if (file.name.toLowerCase().endsWith(".avif")) {
    uploadFile = await convertAvifToJpeg(file)
  }

  const formData = new FormData()
  formData.append("file", uploadFile)

  try {
    const response = await fetch(
      "https://upload-image-endpoint-oak26mtdga-ey.a.run.app/",
      {
        method: "POST",
        body: formData,
      }
    )

    if (!response.ok) {
      const error = `Upload failed: ${response.statusText}`
      await telegram(error)
      throw new Error(error)
    }

    const result = await response.text()
    return result.replace("File uploaded successfully: ", "")
  } catch (error) {
    await telegram(`Upload error: ${error.message}`)
    throw error
  }
}
