import React, { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { useHistory } from "react-router-dom"
import { handleGoogleLogin } from "../../utils/authHelpers" // Add this import

export default function Header() {
  const { currentUser, logout, signupWithGoogle } = useAuth()
  const menuRef = useRef()
  const burgerRef = useRef() // Ref für das Burger-Icon
  const history = useHistory()

  useEffect(() => {
    if (!localStorage.getItem("referrer")) {
      const params = new URLSearchParams(window.location.search)
      const via = params.get("via")
      const ref = params.get("ref")
      const httpReferrer = document.referrer
      const keyword = params.get("q") || params.get("text") // Google, Bing, or Yandex search term
      if (via) {
        localStorage.setItem("referrer", via)
      } else if (ref) {
        localStorage.setItem("referrer", ref)
      } else if (httpReferrer) {
        const referrerUrl = new URL(httpReferrer)
        if (
          referrerUrl.hostname.includes("google") ||
          referrerUrl.hostname.includes("bing") ||
          referrerUrl.hostname.includes("yandex")
        ) {
          localStorage.setItem("referrer", referrerUrl.hostname.split(".")[1])
          if (keyword) {
            localStorage.setItem("search_keyword", keyword)
          }
        } else {
          localStorage.setItem("referrer", httpReferrer)
        }
      }
    }
  }, [])

  const handleLogout = async () => {
    await logout()
    history.push("/")
  }

  // Remove the handleGoogleLogin function from here

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (burgerRef.current && burgerRef.current.contains(event.target)) {
        return
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [menuRef])

  return (
    <div className="py-4 w-full h-16 flex justify-between text-white border-b border-neutral-700 bg-black sticky top-0 left-0 px-3 md:px-6">
      <div className="flex items-center">
        <Link to="/" className="flex items-center">
          {/* <img src={logo} alt="Logo" className="h-10 w-10 mr-2" /> */}

          <span className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
            Clarity AI
            <sup className="text-xs text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 align-middle">
              TM
            </sup>
          </span>
        </Link>
      </div>
      <div>
        <div className="flex">
          <div className="flex items-center md:gap-10 gap-2">
            <Link to="/api">
              <p className="font-bold hidden md:block">API</p>
            </Link>
            <Link to="/comfyui">
              <p className="font-bold hidden md:block">ComfyUI</p>
            </Link>
            {currentUser && (
              <Link to="/pricing">
                <p className="font-bold">Pricing</p>
              </Link>
            )}

            {/* <a href="https://billing.stripe.com/p/login/bIYdTP4ih4Jxfqo9AA">
              <p className="font-bold text-gray-400 hidden md:block">Billing</p>
            </a> */}

            <Link to="/billing">
              <p className="font-bold text-gray-400 hidden md:block">Billing</p>
            </Link>

            {currentUser ? (
              <button onClick={handleLogout}>
                <p className="font-bold whitespace-nowrap hidden md:block">
                  Logout
                </p>
              </button>
            ) : (
              <button
                className="font-bold whitespace-nowrap"
                onClick={() => handleGoogleLogin(signupWithGoogle, history)} // Update this line
              >
                Log in
              </button>
            )}
            <Link to="/dashboard">
              <button className="w-24 h-8 rounded-lg font-bold text-white bg-gradient-to-r via-blue-500  to-emerald-600 from-sky-400 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate">
                Upscale
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
